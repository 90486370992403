import React from "react";
import styled from "styled-components";
import { useSpecialOutcomeData } from "../../hooks/special-edu-student-outcome";

const Outcome = (props) => {

    const data = useSpecialOutcomeData()
    let outcomeData = data.filter((data)=>data.slug === props.slug)[0]
    return (
        <EducationalGoalStyled>
            <EducationalGoalWrapperStyled>
                <div>
                    <h3 key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: outcomeData?.heading }} />
                    <p key={`sub-heading`} id="custom_counselling_subheading" dangerouslySetInnerHTML={{ __html: outcomeData?.sub_heading }} />
                </div>

                <EducationalGoalCardWrapperStyled>
                    {outcomeData?.student_outcome?.map((list, index) => {
                        return (
                            <EducationalGoalCardStyled key={list.id}>
                                <img src={list?.img?.url} alt="" />
                                <span className="evaluate_profile_name">{list?.img_sub_heading}</span>
                            </EducationalGoalCardStyled>
                        );
                    })}
                </EducationalGoalCardWrapperStyled>
            </EducationalGoalWrapperStyled>
        </EducationalGoalStyled>
    );
};
const EducationalGoalStyled = styled.div`
	margin-top: 2rem;
	padding: 0 1em;
`;

const EducationalGoalWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 4em;
	padding-bottom: 7em;
	& h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2a264c;
	}
    & p {
        font-size: 20px;
        text-align: center;
        margin: 10px 0 0;
    }

    #custom_counselling_heading{
        font-size: 2.5rem;
    }
    

	@media (max-width: 500px) {
		gap: 1em;
		& h3 {
			font-size: 1.75rem;
			line-height: 34px;
		}
        #custom_counselling_heading {
            font-size: 1.5rem;
        }
	}
`;

const EducationalGoalCardWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;
	@media (max-width: 500px) {
		gap: 0em;
	}
	@media (max-width: 317px) {
		gap: 0.4em;
	}
`;

const EducationalGoalCardStyled = styled.div`
	min-width: 20em;
	max-width: 20em;
	min-height: 10em;
	max-height: 10em;
	padding: 1em 3.34375em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1em;
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	.evaluate_profile_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 1rem;
		line-height: 24px;
		text-align: center;
		color: #2a264c;
		max-width: 12rem;
	}

	.click_arrow {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background: #f1f1f1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.fa-angle-right {
		color: #2a264c;
		font-size: 1.2rem;
		height: 1em;
	}
	@media (max-width: 500px) {
		width: 100%;
		padding: 1em;
		gap: 0.25em;
        box-shadow: none;
        border: 10px solid #FDF3F3;
		& img {
			height: 75px;
			width: 75px;
		}

		.evaluate_profile_name {
			font-size: 0.9rem;
			line-height: 19px;
            max-width: 20rem;
		}
		
	}

	@media (max-width: 353px) {
		min-width: 7em;
		max-width: 7em;

		.evaluate_profile_name {
			font-size: 0.7rem;
		}
	}
	@media (max-width: 317px) {
		& img {
			height: 40px;
			width: 40px;
		}
	}
`;
export default Outcome;
