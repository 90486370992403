import { useStaticQuery, graphql } from "gatsby";

export const useSpecialOutcomeData = () => {
    const { allStrapiSpecialEduStudentOutcome } = useStaticQuery(
        graphql`
      query specialOutcomeQuery {
        allStrapiSpecialEduStudentOutcome {
          nodes {
            heading
            sub_heading
            slug
            student_outcome{
img_sub_heading
                img{
                    url
                }
            }
          }
        }
      }
    `
    );
    return allStrapiSpecialEduStudentOutcome.nodes;
};
