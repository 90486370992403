import React from "react";
import styled from "styled-components";
import { useBoard } from "../../hooks/open-school-curriculum-board";
const CurriculumBoard = () => {
	const data = useBoard()
	const boardData = data[0]
	return (
        <ScheduleSessionStyled>
            <div>
                <p id="help_you_make_right_choice_head1">{boardData?.heading}</p>
            </div>
            <div>
                <p id="sub-heading" dangerouslySetInnerHTML={{__html:boardData?.sub_heading}}/>
            </div>
        </ScheduleSessionStyled>
    )
}
const ScheduleSessionStyled = styled.section`
	display: flex;
    flex-direction:column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 7.5rem;
	#help_you_make_right_choice {
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2rem 15.875rem;
	}
    #sub-heading{
        margin-top: 1rem;
		text-align: center;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #2a264c;
		max-width: 23rem;
		/* min-width: 23rem; */
    }
	#schedule_session {
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #2a264c;
		border: none;
		outline: none;
		background: #f9ce4b;
		border-radius: 8px;
		padding: 0.90625rem 1.75rem;
	} 
border-radius: 12px;
    }
	#help_you_make_right_choice_head1 {
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.75rem;
	}
	@media (max-width: 1200px) {
		#help_you_make_right_choice {
			padding: 2rem 0rem;
			width: 100%;
		}
	}
	@media (max-width: 500px) {
		#help_you_make_right_choice {
			padding: 2rem;
		}
		#help_you_make_right_choice_head1 {
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 29px;
		}
		#help_you_make_right_choice_head2 {
			font-size: 0.875rem;
			line-height: 17px;
			font-weight: 400;
		}
	}
	@media (max-width: 325px) {
		#help_you_make_right_choice {
			padding: 1rem;
		}
		#help_you_make_right_choice_head1 {
			font-size: 1.3rem;
		}
	}
	@media (max-width: 313px) {
		#help_you_make_right_choice {
			padding: 0.5rem;
		}
	}
`;
export default CurriculumBoard