import React from "react";
import Layout from "../components/layout.component";
import Outcome from "../components/commonComponent/outcome";
import Hero from "../components/commonComponent/hero";
import Enquiry from "../components/commonComponent/enquiry";
import PersonalizeClass from "../components/commonComponent/personlizedClass";
import HowToApply from "../components/commonComponent/howToapply";
import ProgramsOpen from "../components/commonComponent/programsOpen"
import ParentFeedback from "../components/OpenSchool/parentFeedback";
import CurriculumBoard from "../components/OpenSchool/curriculumBoard";
import SEO from '../components/seo'
const OpenSchool = () => {
    return (
        <Layout>
            <Hero slug="Open-School" />
            <PersonalizeClass slug='openschool'/>
            <Enquiry slug="Open-School" />
            <Outcome slug="Open-School" />
            <ParentFeedback />
            <CurriculumBoard />
            <ProgramsOpen slug="Open-School"/>
            <HowToApply slug="Open-School" />
        </Layout>
    )
}
export const Head = () => {
    const schemaOrgJSONLD = [
        {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "Online Classes for Kids",
        "image": "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/5c42c38121314a0cb48b2524f64ad570.svg",
        "description": "Get your kid ready for the future through the most efficient online classes for kids in India.",
        "brand": {
            "@type": "Brand",
            "name": "The Real School"
        },
        "sku": "TRS01",
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "bestRating": "5",
            "worstRating": "3",
            "ratingCount": "101189"
        },
        "review": [
            {
                "@type": "Review",
                "name": "Online Classes for Kids",
                "reviewBody": "Practical brilliance,Real School shapes young minds remarkably well.",
                "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "4.9",
                    "bestRating": "5",
                    "worstRating": "2"
                },
                "datePublished": "2021-07-11",
                "author": { "@type": "Person", "name": "Sakshi" },
                "publisher": { "@type": "Organization", "name": "The Real School" }
            },
            {
                "@type": "Review",
                "name": "Online Classes for Kids",
                "reviewBody": "Real School's online platform fosters bright futures, highly recommended!",
                "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "4.9",
                    "bestRating": "5",
                    "worstRating": "1"
                },
                "datePublished": "2021-08-07",
                "author": { "@type": "Person", "name": "Varsha" },
                "publisher": { "@type": "Organization", "name": "The Real School" }
            },
            {
                "@type": "Review",
                "name": "Online Classes for Kids",
                "reviewBody": "A+ for Real School's projected approach to developing knowledge.",
                "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "4.9",
                    "bestRating": "5",
                    "worstRating": "3"
                },
                "datePublished": "2022-11-22",
                "author": { "@type": "Person", "name": "Harkeerat's Mother" },
                "publisher": { "@type": "Organization", "name": "The Real School" }
            }
        ]
    }]
    return (
        <SEO description="You can go ahead with an online learning for children platform to let your kids learn with the best guidance and content. The online activity classes for kids are extremely supportive. Let's online study for kids."
            title="The Real School | Join the Best Online Classes for Kids"
            keywords="best online classes for kids online classes for kids india best online schools"
            children={
                <script type="application/ld+json">
                        {JSON.stringify(schemaOrgJSONLD)}
                </script>
            }
        />
    )
}

export default OpenSchool